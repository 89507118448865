.tab-info{
    width: 1200px;
    height: 644px;
    position: relative;
    overflow: hidden;
  }
  .tab-info ul{
    display: block;
    width: 4800px;
    height: 100%;
    float:left;
    position: absolute;
    z-index: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  .boxStyleLi{
    display: inline-block;
    width: 1200px;
    height: 644px;
    padding-left: 60px;
    box-sizing: border-box;
  }
  .onePosition{
    left: 0;
  }
  .twoPosition{
    left: -1200px;
  }
  .therePosition{
    left: -2400px;
  }
  .fourPosition{
    left: -3600px;
  }
