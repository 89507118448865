.App {
  font-family: Arial, Helvetica, sans-serif; 
  overflow-x: hidden;
  
}
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select;
          user-select: $select;
}
@mixin button-reset{
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
}
@keyframes banneroverlayGrey {
  0% {
      left: -1000px;
  }
  100% {
      left: 0;
  }
}



.header{
  width: 100vw;
  height: 72px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  .logo{
    padding:12px;
    width: 143px;
    height: 48px;
    span{
      font-size:32px;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      color:rgba(85,85,85,1);
      line-height:36px;
      letter-spacing:3px;
    }
  }
  .menu-wrap{
    display: flex;
    padding: 20px;
    align-items: flex-end;
    .menu{
      display: flex;
      height: 25px;
      .item{
        width: 120px;
        text-align: center;
        text-decoration: none;
        font-size:14px;
        font-family:PingFang-SC-Regular,PingFang-SC;
        font-weight:400;
        color:rgba(85,85,85,1);
        line-height:16px;
        letter-spacing:1px;
        position: relative;
      }
      .item.active{
        color: #5345F0FF;
      }
      .item.active:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -5px;
        left: 50%;
        width:8px;
        height:3px;
        margin-left: -4px;
        background:rgba(83,69,240,1);
        box-shadow:0px 2px 5px 0px rgba(83,69,240,0.8);
        border-radius:2px;
       }
    }
    button.login-btn{
      @include button-reset;
      @include user-select(none);
      width:100px;
      height:32px;
      background:linear-gradient(90deg,rgba(125,94,255,1) 0%,rgba(93,79,255,1) 100%);
      box-shadow:0px 3px 7px 0px rgba(107,85,255,0.3);
      border-radius:27px;
      border: none;
      color: #fff;
    }
    .login-btn:hover{
      color: white;
      border-color: currentColor;
      background-color: #3f51b5;
    }
    .login-btn:focus {
      outline: none;
    }
  }
}
/* 设置title样式 */
.App{
  .title{
    padding: 80px 0 50px 0;
    text-align: center;
    line-height:36px;
    letter-spacing:1px;
    span{
      width: 200px;
      font-size:32px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(34,34,34,1);
      position: relative;
    }
    span::before{
      content: '';        
      position: absolute;
      width: 32px;
      height: 25px;
      left: -50px;
      top: 50%;
      margin-top: -12px;
      background: url(./assets/img/left.png);
    }
    span::after{
      content: '';          
      position: absolute;         /*定位背景横线的位置*/
      width: 32px;
      height: 25px;
      right: -50px;
      top: 50%;
      margin-top: -12px;
      background: url(./assets/img/right.png);
    }
  }
}


.section-1{
  width: 100vw;
  height: 560px;
  // background-color:#cddc39;
}

.section-2{
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: -30px;
  margin-bottom: 30px;
  .content {
    display: flex;
    justify-content: space-around;
    .box-item {
      width: 200px;
      height: 200px;
    }
    .box-item:nth-child(1){
      background: url(./assets/img/scene-01.png) no-repeat;
      background-size: cover;
    }
    .box-item:nth-child(2){
      background: url(./assets/img/scene-02.png) no-repeat;
      background-size: cover;
    }
    .box-item:nth-child(3){
      background: url(./assets/img/scene-03.png) no-repeat;
      background-size: cover;
    }
    .box-item:nth-child(4){
      background: url(./assets/img/scene-04.png) no-repeat;
      background-size: cover;
    }
    .box-item:nth-child(5){
      background: url(./assets/img/scene-05.png) no-repeat;
      background-size: cover;
    }
    .box-item:nth-child(6){
      background: url(./assets/img/scene-06.png) no-repeat;
      background-size: cover;
    }
  }
}

.section-3{
  width: 1200px;
  margin: 0 auto;
  height: 537px;
  .title{
    padding: 30px;
  }
  .content{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    margin-bottom: 50px;
    .box{
      width: 238px;
      text-align: center;
      .box-item{
        width: 238px;
        height: 238px;
      }
      .name{
        font-size:24px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(34,34,34,1);
        line-height:28px;
        margin: 10px;
      }
      .desc{
        font-size:16px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(136,136,136,1);
        line-height:28px;
      }
    }
    .box:nth-child(1) .box-item{
      background:url(./assets/img/product-01.png) no-repeat;
    }
    .box:nth-child(2) .box-item{
      background:url(./assets/img/product-02.png) no-repeat;
    }
    .box:nth-child(3) .box-item{
      background:url(./assets/img/product-03.png) no-repeat;
    }
  }
}

.section-4{
  width: 100vw;
  height: 965px;
  background-color: #F8F8F8FF;
  .content {
    width: 1200px;
    margin: 0 auto;
    .tab-wapper{
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #ccc;
      .tab{
        padding: 0 30px;
        cursor: pointer;
        font-size:24px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(34,34,34,1);
        line-height:28px;
        padding: 17px;
        position: relative;
        margin: 0 70px;
      }
      .tab.active{
        color:rgba(83,69,240,1);
      }
      .tab.active:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -3px;
        left: 50%;
        width:160px;
        height:8px;
        margin-left: -80px;
        background: url(./assets/img/activetab.png);
       }
    }
    .tab-info {
      padding: 50px 0;
      position: relative;
      overflow: hidden;
      height: 644px;

      .banner_overlayGrey {
        // animation: banneroverlayGrey 500ms linear both;
        // position: absolute;
        width: 1200px;
        opacity: 1;
        display: inline-block;

      }
      .block-i{
        width: 500px;
        height: 282px;
        margin: 20px;
        display: inline-block;
        position: relative;
        .text{
          position: absolute;
          bottom: 0px;
          width:500px;
          height:48px;
          background:linear-gradient(270deg,rgba(204,93,255,1) 0%,rgba(92,65,255,1) 100%);
          border-radius:0px 0px 8px 8px;
          padding: 0 16px;
          box-sizing: border-box;
          .name{
            font-size:20px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(255,255,255,1);
            line-height:48px;
            padding: 0 24px;
          }
          .desc{
            font-size:16px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(255,255,255,.8);
            line-height:48px;
          }
        }
      }
    }
  } 
}

.section-5{
  width: 100%;
  height: 706px;
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .box-item{
      margin: 20px
    }
  }
}

.section-6{
  width: 100%;
  height: 905px;
  background-color: #F8F8F8FF;
  .content {
   
    .case {
      width: 1320px;
      margin: 0 auto;
      height: 296px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin-bottom: 56px;
      .box-item{
        margin: 5px 10px;
        
      }
    }
    .feedback{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .box-item{
        width:400px;
        height:330px;
        background: url(./assets/img/feedback-bg.png);
        padding: 50px 20px 50px 40px;
        box-sizing: border-box;
        .name{
          display: flex;
          align-items: center;
          img{
            margin: 0 10px;
            width:64px;
            height:64px;
          }
          span{
            font-size:24px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(34,34,34,1);
            line-height:33px;
          }
        }
        p{
          margin: 10px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(85,85,85,1);
          line-height:24px;
        }
      }
    }
    
  }
}

.section-7{
  width: 100vw;
  height: 144px;
  background: url(./assets/img/bg.png);
  .content{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 40px;
    .btn{
      @include button-reset;
      @include user-select(none);
      width: 214px;
      border-radius: 5px;
      height: 68px;
      border: none;
      color: white;
      background-color: cornflowerblue;
      margin: 0px 100px;
    }
    .btn:nth-child(1){
      background: url(./assets/img/but1.png);
    }
    .btn:nth-child(2){
      background: url(./assets/img/but2.png);
    }
    .btn:focus {
      outline: none;
    }
  }
}

.footer{
  width: 100vw;
  background: url(./assets/img/footer-bg.png);
  .content{
    width: 1200px;
    height: 448px;
    margin: 0 auto;
    padding-top: 100px;
    // padding: 80px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      flex-direction: column;
      img{
        // margin: 10px;
        margin-bottom: 50px;
      }
    }
    .middle{
      width: 450px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .one{
        display: flex;
        a{
          font-size:16px;
          font-family:PingFang-SC-Regular,PingFang-SC;
          font-weight:400;
          color:rgba(255,255,255,.8);
          line-height:22px;
          margin-right:64px;
          text-decoration: none;
          position: relative;   
        }
        a::after{
          content: '';
          position: absolute;
          width: 1px;
          height: 24px;
          right: -32px;
          // background-color: #fff;
          background:linear-gradient(180deg,rgba(54,57,61,1) 0%,rgba(97,100,105,1) 50%,rgba(54,57,61,1) 100%);
        }
        a:last-child::after{
          display: none;
        }
      }
      .two{
        font-size:24px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,.8);
        line-height:40px;
      }
      .copyright{
        width: 388px;
        font-size:14px;
        font-family:PingFang-SC-Regular,PingFang-SC;
        font-weight:400;
        color:rgba(255,255,255,.4);
        line-height:28px;
      }
    }
    .right{
      p{
        margin-top: 20px;
        text-align: center;
        font-size:16px;
        font-family:PingFang-SC-Regular,PingFang-SC;
        font-weight:400;
        color:rgba(255,255,255,.6);
        line-height:22px;
      }
    }
  }
}
